.container {
  overflow: hidden;
  height: 100vh;
}

.content {
  margin-right: var(--sidepanel-width);
  height: 100%;
  transition: 200ms;
  display: flex;
  flex-direction: column;
  justify-items: stretch;
}

.cell {
  white-space: unset;
  overflow: visible;
}

.center {
  justify-content: center;
}

.columns {
  display: flex;
  flex-direction: column;
}
