.container {
  overflow: hidden;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: #333;
  position: relative;
  width: 100%;

  & > svg {
    width: 100%;
    height: 100%;
    flex-grow: 1;
    display: block;
  }
}

.facePath {
  transition: 200ms;
}

.startEditing {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform:translate(-50%, -50%);
}

.buttons {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 10px;
}

.button {
  width: 44px;
  height: 44px;
  background: #fff;
  margin: 4px;
  cursor: pointer;
  border-radius: 0.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px;
  transition: 200ms;
  text-decoration: none;
  color: #555;
  --tone1: #555;

  svg {
    width: 60%;
    height: auto;
  }

  &.selected, &:hover {
    background: var(--secondary-color);
    color: var(--secondary-text-color);
    --tone1: var(--secondary-text-color);
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
  }
}

.buttonText {
  font-size: 12px;
}

.imageButtons {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 10px;
}

.imageButton {
  width: 100px;
  height: 100px;
  background: #fff;
  margin: 4px;
  cursor: pointer;
  border-radius: 0.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 200ms;
  text-decoration: none;
  color: #555;
  overflow: hidden;
  position: relative;

  .imageButtonTitle {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    font-size: 2em;
    background: rgba(0,0,0,0.5);
    color: #fff;
  }

  &.selected, &:hover {
    background: var(--secondary-color);
    color: var(--secondary-text-color);
    --tone1: var(--secondary-text-color);
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
  }

  .selected .imageButtonTitle {
    background: var(--secondary-a20-color);
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    display: block;
  }
}

.backButton {
  position: absolute;
  top: 0;
  right: 0;
  margin: 20px;
  width: 44px;
  height: 44px;
  --tone1: var(--primary-text-color);
  transition: 200ms;
  z-index: var(--zIndexPopup);

  svg {
    display: block;
    height: 100%;
    width: 100%;
  }

  &:hover {
    transform: scale(1.1);
  }
}

.movingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0);
  cursor: move;
}