.container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 4px;
}

// hack to change placeholder color
.date:invalid {
  color: rgba(106, 103, 103, 0.6) !important;
}

.type {
  display: flex;
  gap: 12px;

  span {
    border: 1px solid rgba(0, 0, 0, 0.85);
    padding: 12px 20px;
    cursor: pointer;

    &.selected {
      background-color: rgba(26, 26, 26, 0.8);
      color: white;
    }
  }
}

.checkboxRow {

  label {
    cursor: pointer;
    display: flex;
    align-items: center;
    user-select: none;
  }

  input {
    cursor: pointer;
    margin-left: 12px;
    height: 20px;
    width: 20px;
  }
}
