.container {
  position: relative;
}

.title {
  position: absolute;
  padding: 0 10px;
  font-size: 12px;
  top: 8px;
  left: 0;
  transition: 200ms;
  pointer-events: none;
  color: #6f94bd;
  font-size: 11px;
}

.input {
  font-family: var(--font);
  border-radius: 7px;
  color: var(--secondary-w90-text-color);
  letter-spacing: -0.41px;
  margin: 0;
  min-width: 0;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  background: #fff;
  line-height: 1.5em;
  width: 100%;
  outline: none;
  padding: 10px;
  border: 1px solid #ddd;
  font-size: 16px;
  height: auto;
  box-shadow: none;
  transition: all 200ms ease-in-out;
  margin: 5px 0;

  &:focus{
    border-color: #6f94bd;
    box-shadow: 0 0 0 2px #c3d8f0;
  }
}

.date:invalid {
  color: rgba(106, 103, 103, 0.6) !important;
}

input::placeholder, textarea::placeholder{
  color: #6f94bd;
}

.input:not(:placeholder-shown) {
  padding-top: calc(15px + 6px);
  padding-bottom: calc(15px - 6px);
}

.input:placeholder-shown + .title {
  opacity: 0;
  transform: translateY(2px);
}

.error .input {
  background: #f3d4d7;
  border-color: #c84755;
}

.error .title {
  color: #c84755;
}

.disabled .input {
  background: none;
  color: #999;
}


.switchRow{
  display: flex;
  gap: 20px;
}
