.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.feature {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  border-top: 1px solid #999;
  padding: 10px;
  transition: 200ms;
  cursor: pointer;

  &:first-child {
    border-top: 0;
  }

  &:hover {
    background: var(--secondary-color);
    color: #fff;
  }
}

.featureContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: stretch;
}

.featurePrice {
  
}

.featureName {
  font-weight: bold;
}

.featureDescription {

}

.selected {
  background: var(--secondary-color);
  color: #fff;
}