.cell {
  display: flex;
  flex-direction: row;
  display: flex;
  // align-items: center;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  min-width: var(--nav-closed-width);
  transition: 200ms;
  padding: 10px 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #445560;
  font-size: 0.8em;
  border-bottom: 1px solid rgb(233, 233, 233);
}
.cell span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cell.editable {
  position: relative;
}
.cell.pilled {
  padding: 8px 14px;
}
.cell.deleting {
  animation-name: deleting;
  animation-duration: 300ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
  animation-fill-mode: both;
  animation-direction: alternate;
}

a.cell {
  text-decoration: none;
  color: var(--primary-color);
}
a.cell:hover {
  text-decoration: underline;
}

.cell a{
  text-decoration: none;
}

.cell a:hover{
  text-decoration: underline;
}

.cell img {
  height: calc(var(--nav-closed-width) - 20px);
  width: auto;
  display: block;
}

.sticky {
  position: sticky;
  left: 0;
  z-index: 0;
  background: #F9F9F9;
  border-right: 1px solid var(--french-pass);
  overflow: visible;

  &:after{
    content: "";
    position: absolute;
    top: 0;
    right: -5px;
    bottom: 0;
    width: 5px;
    background: linear-gradient(90deg, rgba(0,0,0,0.05), rgba(0,0,0,0));
  }
}

.cell.even, .sticky.even {
  background: rgb(244, 244, 244);
}

.cell.active {
  background: var(--secondary-w80-color);
  --tone1-default: var(--primary-w40-color);
  --tone2-default: var(--primary-w80-color);

}

// .cell.active.even {
//   background: var(--primary-w90-color);
// }

@keyframes deleting {
  to {
    background: #ff7575;
    --tone1: #fff;
    --tone2: #fff;
    color: #fff;
  }
}

@media (prefers-color-scheme: dark) {
  .container, .cell, .cell.active, .cell.even {background-color: #222;}

  .cell {
    border-bottom-color: #444;
    color: #eee;
  }

  .cell.active {
    background: var(--secondary-b90-color);
    --tone1-default: var(--primary-b40-color);
    --tone2-default: var(--primary-b80-color);
  }
}