.circles {
  display: grid;
  grid-template-rows: repeat(5, 20px);
  grid-template-columns: repeat(5, 20px);
}

.circle {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid #333;
  cursor: pointer;
  transition: 200ms;

  &:hover {
    background: var(--secondary-w20-color);
  }

  &.selected {
    background: var(--secondary-color);
  }
}

.dash {
  width: 100%;
  height: 2px;
  margin: auto 0;
  background: #333;
}

.vDash {
  height: 100%;
  width: 2px;
  margin: 0 auto;
  background: #333;
}