.container {
  position: relative;
  display: inline-block;
}

.prefix {
  padding-right: 6px;
}

.super {
  font-weight: normal;
  font-size: 0.7em;
  margin-right: 1px;
}

.cut {
  text-decoration: none;
  position: relative;

  &:before {
    content: " ";
    display: block;
    width: 100%;
    border-top: 3px solid #3d3d3d;
    position: absolute;
    transform: rotate(-32deg);
    z-index: 10;
    top: 11px;
  }
}