.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 10;
  flex-grow: 1;
}

.scrollable {
  padding: 10px;
}

.firebaseLink {
  text-align: center;
  margin-top: 20px;
}
