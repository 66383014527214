.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.box {
  margin: 4px;
}

.enhancingBox, .enhancingBoxDisabled {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2a4854;
  --tone1: #fff;
  flex-grow: 1;

  svg {
    width: 40%;
    height: auto;
    display: block;
  }
}

.enhancingBoxDisabled {
  background: #999;
  --tone1: transparent;
}

.error {
  font-size: 10px;
  color: red;
}

.procesing {
  font-size: 10px;
  color: blue;
}

.status {
  font-size: 10px;
  color: rgb(140, 140, 140);
}

.warning {
  display: flex;
  align-items: center;
  justify-content: center;
  background: red;
  --tone1: #fff;
  flex-grow: 1;

  svg {
    width: 40%;
    height: auto;
    display: block;
  }
}