/* Recoleta */
@font-face {
  font-family: 'Recoleta';
  font-weight: bold;
  src: url("./recoleta/Recoleta-Bold.ttf");
}

@font-face {
  font-family: 'Recoleta';
  font-weight: normal;
  src: url("./recoleta/Recoleta-Regular.ttf");
}

/* Greycliff */
@font-face {
  font-family: 'Greycliff';
  font-weight: normal;
  src: url("./greycliff/GreycliffCF-Regular.otf");
}

@font-face {
  font-family: 'Greycliff';
  font-weight: bold;
  src: url("./greycliff/GreycliffCF-DemiBold.otf");
}

/* Rubik */
@font-face {
  font-family: 'Rubik';
  src: url('./Rubik/Rubik-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('./Rubik/Rubik-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('./Rubik/Rubik-Bold.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}


/* Inconsolata */
@font-face {
  font-family: 'Inconsolata';
  font-weight: normal;
  src: local('Inconsolata Regular'), url("./inconsolata/Inconsolata-Regular.ttf");
  font-display: swap;
}
