.container {
  width: 100%;
  position: relative;
  
  img {
    max-width: 100%;
    cursor: pointer;
    display: block;
  }
}

.emptyContainer {
  height: 200px;
}

.badge {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px;
  background-color: rgba(0, 0, 0,0.8);
  color: white;
  font-size: 11px;
  letter-spacing: -0.03em;
}

.targets {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 1;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 200ms;
}

.largeImage, .uploadImage {
  cursor: pointer;
  flex-basis: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  --tone1: #fff;
  color: #fff;
  font-size: 12px;
  background: rgba(0, 0, 0,0.5);
  opacity: 0;
  transition: 200ms;

  input {
    display: none;
  }

  svg {
    width: auto;
    height: 20px;
    display: block;
  }
}

.container:hover, .emptyContainer {
  .targets {
    pointer-events: all;
    background: rgba(0, 0, 0,0.5);
  }
  .largeImage, .uploadImage {
    opacity: 0.3;
    &:hover {
      opacity: 1;
    }
  }
}