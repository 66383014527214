.container {
  overflow: hidden;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.content {
  margin-right: var(--sidepanel-width);
  transition: 200ms;
  display: flex;
  flex-direction: column;
  justify-items: stretch;
  flex-grow: 1;
}

.columns {
  display: flex;
  flex-direction: column;
}

.faded {
  opacity: 0.5;
}

.usageArea {
  border-bottom: 1px solid rgba(0, 0, 0, 0.26);
  padding-bottom: 10px;
  margin-bottom: 10px;

  p {
    display: flex;
    justify-content: space-between;
    margin: 0;
    gap: 40px;
    margin-bottom: 6px;
  }

  &:last-child {
    border-bottom: unset;
    margin-bottom: unset;
    padding-bottom: unset;
  }
}
