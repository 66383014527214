.container {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    display: block;
  }

  input {
    display: none;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  font-size: 2em;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  transition: 200ms;
  &:hover .box {
    opacity: 0.4;
  }
}

.overlay .box {
  flex-basis: 0;
  flex-grow: 1;
  transition: 200ms;
  opacity: 0;
  background: rgba(0,0,0,0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 1;
  }
}

.uploadUplaceholder {
  width: 100%;
  height: 100px;
  background: #efefef;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 200ms;
}

.container:hover .uploadUplaceholder {
  background: #aaa;
}

.sizes {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 4px 8px;
}

.size {
  font-size: 8px;
  background: var(--secondary-color);
  color: var(--secondary-text-color);
  padding: 0.3em 0.75em;
  border-radius: 10em;
}