.container {
  position: relative;
  margin-bottom: 1em;
}

.title {
  padding: 0 10px 4px 10px;
  font-size: 12px;
  transition: 200ms;
  pointer-events: none;
  color: rgb(185, 194, 247);
}

.content {
  display: flex;
  flex-direction: row;
  border: 1px solid var(--primary-w20-color);
  color: var(--primary-w20-text-color);
  border-radius: 100em;
  overflow: hidden;
}

.input {
  display: none;
}

.label {
  flex: 1;
  flex-basis: 0;
  text-align: center;
  font-family: var(--font);
  color: var(--secondary-w90-text-color);
  letter-spacing: -0.41px;
  padding: 0.5em;
  position: relative;
  transition: 200ms;
  cursor: pointer;

  &:not(:first-child):before {
    position: absolute;
    content: "";
    height: 100%;
    width: 1px;
    background: var(--primary-w20-color);
    color: var(--secondary-w20-text-color);
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
  }

  &:hover {
    background: var(--primary-w10-color);
    color: var(--secondary-w10-text-color);
  }

  &.checked {
    background: var(--primary-color);
    color: var(--secondary-text-color);
  }
}



// .input {
//   font-family: var(--font);
//   border-radius: 7px;
//   color: var(--secondary-w90-text-color);
//   letter-spacing: -0.41px;
//   margin: 0;
//   min-width: 0;
//   flex-grow: 1;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   display: block;
//   background: #F6F6F6;
//   line-height: 1.5em;
//   width: 100%;
//   outline: none;
//   padding: 20px 10px;
//   border: 1px solid transparent;
//   font-size: 16px;
//   height: auto;
//   box-shadow: none;
//   transition: all 200ms ease-in-out;
// }

// input::placeholder{
//   color: rgb(185, 194, 247);
// }

// .input:not(:placeholder-shown) {
//   padding-top: calc(20px + 6px);
//   padding-bottom: calc(20px - 6px);
// }

// .input:placeholder-shown + .title {
//   opacity: 0;
//   transform: translateY(2px);
// }

// .error .input {
//   background: #f3d4d7;
//   border-color: #c84755;
// }

// .error .title {
//   color: #c84755;
// }