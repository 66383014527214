.primaryButton {
  background: var(--secondary-color);
  color: var(--secondary-text-color);
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  font-family: var(--font);
  border-radius: 3em;
  color: white;
  font-weight: 400;
  font-size: 11px;
  padding: 10px 16px;
  text-decoration: none;

  &:hover {
    background: var(--secondary-b20-color);
    color: var(--secondary-b20-text-color);
    box-shadow: 0 1px 3px rgba(0,0,0,.2);
  }

  &:disabled {
    background: var(--lightGray);
    background: var(--primary-w50-color);
    color: var(--primary-w90-color);
    cursor: not-allowed;
  }

  &.autoWidth {
    width: unset;
    padding: 0 30px;
  }
}