.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}


.checkbox {
  width: 20px;
  height: 20px;
  border: 1px solid var(--border-color, #BDBDBD);
  border-radius: 4px;
  position: relative;
  transition: 200ms;
  margin: 3px 10px 2px 0;
  display: block;
  flex-shrink: 0;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.checkmark {
  color: transparent;
  transition: 200ms;
  font-size: 14px;
  font-weight: bold;
}

.checkbox.checked {
  border-color: var(--primary-color);
}
.checkbox.checked .checkmark {
  color: var(--primary-color);
}

.inputCheckbox {
  display: none;
}
