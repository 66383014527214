.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 10;
  flex-grow: 1
}

.input {
  margin: 12px;
}

.tiledBackgroundWrap {
  position: relative;
}

.tiledBackground {
  display: block;
  width: 100%;
  height: 400px;
  background-size: 50%;
  background-repeat: no-repeat;

  &.tiled {
    background-repeat: repeat;
  }
}

.smallTiledBackground {
  display: block;
  width: 100%;
  height: 200px;
  background-size: 12.5%;
  background-repeat: no-repeat;

  &.tiled {
    background-repeat: repeat;
  }
}

.square88 {
  width: 44px;
  height: 44px;
  display: block;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: block;
    margin: 0;
    padding: 0;
  }
}