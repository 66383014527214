.container {
  background: white;
  width: 200px;
}

.button {
  cursor: pointer;
  transition: 200ms;
  padding: 0.5em;
  display: block;
  text-decoration: none;
  color: #111;

  &:hover {
    background: var(--secondary-color);
    color: #fff;
  }
}

.selected {
  background: var(--secondary-color);
  color: #fff;
}