.icon {
  --tone1: var(--secondary-color);
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.error {
  width: 100%;
  color: var(--danger);
  font-size: 14px;
  text-align: initial;
  padding: 1em 0;
}
