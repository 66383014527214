.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 10;
  flex-grow: 1
}

.input {
  margin: 12px;
}