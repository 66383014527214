.container {
  margin: 10px;
  background: #fff;
  // box-shadow: 0 2px 3px rgba(0,0,0,0.3);
  // border-radius: 6px;
  overflow: hidden;
  flex-shrink: 0;
}

.title {
  color: rgba(0,0,0,0.5);
  margin: 0 10px;
  font-size: 12px;
  padding: 6px;
}