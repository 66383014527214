.container {
  font-family: var(--fontSec);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #fff;
  color: #000;
}

.section {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
  flex-grow: 1;
  // background: var(--primary-w80-color);
  position: relative;
  flex-basis: 0;

  &:first-child {
    border-bottom: 1px solid #aaa;
  }
}
.section h1 {
  font-size: 5em;
  font-weight: bold;
  font-family: var(--fontSec);
  color: var(--primary-w80-text-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.printButton {
  position: fixed;
  margin: 1em;
  top: 0;
  right: 0;
  width: 5em;
  height: 5em;
  --tone1: #fff;
  --tone2: #fff;
  transition: 200ms;
  cursor: pointer;
  z-index: 1000;

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }

  &:hover {
    box-shadow: 0 2px 5px rgba(0,0,0,0.5);
  }
}

.contactUs {
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 1em;
}

.location {
  font-family: var(--fontTer);
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.locationTitle {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  padding: 0;
  line-height: 1.5em;
}

.locationAddress, .locationRegion {
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.5em;
}


.qrContainer {
  position: absolute;
  left: 0;
  bottom: 0;
  margin: 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.qrcode {
  margin-right: 1em;
}

.products {
  display: flex;
  flex-direction: column;
}

.product {
  display: grid;
  grid-template-columns: max-content max-content 0.5fr 1fr;
  grid-gap: 0.5em;
  margin: 0.5em 0;
  align-items: center;
}

.box {
  display: block;
  width: 4em;
  height: 4em;
  overflow: hidden;
  border-radius: 8px;
  position: relative;

  img {
    width: 100%;
    // height: 100%;
    display: block;
    object-fit: cover;
  }
}

.boxTitle {
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 4px;
  font-size: 10px;
  color: #fff;
  background: rgba(0,0,0,0.8);
  text-align: center;
  font-family: var(--fontTer);
  font-weight: bold;
}

.products {
  padding: 1em;
  flex-grow: 2;
  flex-basis: 0;
}

.productText {
  display: flex;
  flex-direction: column;
  margin: 0.5em 1em;
}

.productTitle {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}
.productSubTitle {
  font-family: var(--fontTer);
  font-size: 16px;
  color: grey;
  margin: 0;
}

.price {
  font-weight: bold;
  font-size: 1.5em;
}

.shipping {
  flex-grow: 1;
  flex-basis: 0;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  padding: 1em;
}

.priceRows {
  display: flex;
  flex-direction: column;
  margin: 1em 0;
}

.priceRow {
  display: flex;
  flex-direction: row;

  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.5em;

  b {
    margin-right: 0.5em;
  }
}

.logo {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

.url {
  display: flex;
  flex-direction: row;
}

.http {
  color: lightgray;
}

@media print {
  .noBreak {
    page-break-inside: avoid;
  }

  .fixedBottom {
    display: block;
    page-break-inside: avoid;
  }

  .printButton {
    display: none;
  }

  .pageBreak {
    display: block;
    clear: both;
    page-break-after: always;
  }
}