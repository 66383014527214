.container {
  flex-shrink: 0;
  // margin: 1em;
  padding: 1em;
  border-radius: 5px;
}

.header {
  font-size: 12px;
  font-weight: normal;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin-bottom: 4px;
  margin-top: 12px;
  color: #a486be;
  display: flex;
  flex-direction: row;
  
  &:first-child {
    margin-top: 0;
  }
}

.row {
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  margin: 2px 0;
  line-height: 1em;
}
  
.line {
  border-bottom: 1px dotted var(--primary-w50-color);
  flex-grow: 1;
  height: 1em;
  margin: 0 3px;
}

.text {
  padding-top: 0.5em;
  font-size: 12px;
}

.value {
  font-family: var(--fontQuad);
  font-size: 18px;
  font-weight: bold;
  // font-size: 13px;
}

.button {
  height: 2.5em;
  width: 2.5em;
  transition: 200ms;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    display: block;
    height: 75%;
    width: 75%;
  }

  &:hover {
    background: var(--secondary-color);
    color: #fff;
  }
}

@media (prefers-color-scheme: dark) {
  .value {color: #fff}

  .container {
    background: #333;
    color: #fff;
    border-color: #444;
  }

  .header {
    color: #666;
  }
}