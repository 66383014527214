.container {
  margin-right: var(--sidepanel-width);
  transition: all 200ms ease;
  overflow: hidden;
  height: 100vh;
  display: flex;
  flex-direction: column;

}

.product {
  margin: 0 5px;
}

.products {
  display: flex;
  flex-direction: column;
}


.printGrouping{
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: .8em;
  margin: .1em 0;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);

  .orderId{
    font-size: 10px;
    margin-left: 4px;

    span{
      color: var(--primary);
    }
  }
}
.container {
  button{
    margin-right: 1em;
  }
}