.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 0.6em;
  font-family: var(--font);
  letter-spacing: -.03em;
  font-weight: 500;
  margin-bottom: 4px;
  color: #777;
}

.box {
  border-radius: .3em;
  width: 2.1rem;
  height: 2.1rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.box img {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: top;
  margin: 0;
  border-radius: .3em;
}
.box .text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  span {
    // font-size: 1.em;
    color: #606060;
    text-transform: lowercase;
  }
}

.clickable {
  cursor: pointer;
}


@media (prefers-color-scheme: dark) {
  .title, .box .text span { color: #fff}
}