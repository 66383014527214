.container {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
  z-index: var(--zIndexPopup);
}

.svg {
  width: 100%;
  height: 100%;
  display: block;
  margin: 0;
  padding: 0;
}

.faceCanvasRange {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: linear-gradient(rgba(0,0,0,0.0), rgba(0,0,0,0.8));
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-bottom: 2em;
}

.smallLines {
  transition: 500ms;
  transition-timing-function: ease-in-out;
}

.range {
  position: absolute;
  bottom: 120px;
  left: 0;
  right: 0;
  height: 95%;
  --bigLineSize: 32px;
  --centerLineSize: 50px;
  --lineSize: 32px;
}


.backButton {
  position: absolute;
  top: 0;
  right: 0;
  margin: 20px;
  width: 44px;
  height: 44px;
  --tone1: var(--primary-color);
  transition: 200ms;
  z-index: var(--zIndexPopup);

  svg {
    display: block;
    height: 100%;
    width: 100%;
  }

  &:hover {
    transform: scale(1.1);
  }
}