.container {
  padding: .5em;
  display: flex;
  justify-content: flex-start;
  background-color: #4c51a50b;
  margin-right: var(--sidepanel-width);
  transition: 200ms;
}

@media screen and (max-width: 768px) {
  .container{
    overflow: scroll;
  }
}
