.container {
  overflow: auto;
  flex-grow: 1;
  flex-basis: 0;
  height: 100%;
  width: 100%;
  position: relative;
  transition: 200ms
}

.content {
  overflow: hidden;
  transition: 200ms
}