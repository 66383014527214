.container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  width: var(--nav-closed-width);
  transition: 200ms;
  overflow: hidden;
  z-index: 100;
  background: #f6f5f8;

  &.opened {
    width: var(--nav-opened-width);
  }
}

.row {
  --row-icon-size: var(--nav-closed-width);
  display: flex;
  flex-direction: row;
  position: relative;
  height: var(--row-icon-size);
  align-items: center;
  flex-shrink: 0;
  text-decoration: none;
  color: #505050;
  transition: 200ms;
  overflow: hidden;

  &.clickable {
    cursor: pointer;
  }
  &.active, &:link:hover, &.clickable:hover {
    background: var(--secondary-w90-color);
    --tone1: var(--secondary-color);
    --tone2: var(--secondary-color);
    --tone2-opacity: 0.2;
    color: var(--secondary-color);
  }

  &.active {
    font-weight: 500;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 0;
    background: var(--secondary-color);
    transition: 200ms;
  }

  &.active:after {
    width: 6px;
  }
}


.rowIcon {
  flex-shrink: 0;
  height: var(--row-icon-size);
  width: var(--row-icon-size);
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  transition: 200ms;
  --tone1: #888;
  --tone2: #4C51A5;

  svg {
    display: block;
    height: 20px;
    width: auto;
  }
}


.toggle {
  position: absolute;
  bottom: 0;
  right: 0;
  width: var(--nav-closed-width);
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: rgba(160,160,160,1);
  cursor: pointer;
  transition: 200ms;
  background: #f6f5f8;

  &:after {
    content: '';
    height: 1px;
    left: 4px;
    right: 4px;
    top: 0;
    background: linear-gradient(90deg, rgba(208, 208, 208, 0), rgb(208, 208, 208), rgba(208, 208, 208, 0));
    position: absolute;
    transition: 200ms;
  }

  svg {
    height: 16px;
  }

  &:hover {
    background: var(--secondary-w90-color);
    --tone1: var(--secondary-color);
    --tone2: var(--secondary-color);
    --tone2-opacity: 0.2;
  }
}

.opened .toggle {
  position: absolute;
  bottom: 0;
  right: 0;
  height: var(--nav-closed-width);
  width: 32px;

  &:after {
    opacity: 0;
  }

  svg {
    transform: rotate(180deg);
  }
}

.footer {
  background: #f6f5f8;
}

@media print {
  .container {
    display: none;
    width: 0;
  }
}



@media (prefers-color-scheme: dark) {
  .container {
    background-color: #333;
    color: #fff
  }
  .row{
    color: #fff;
    font-weight: normal;
  }
}