.range {
  display: flex;
  &.horizontal {
    flex-direction: column;
  }
  &.vertical {
    flex-direction: row;
  }
}

.dragging {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.valueCircle {
  font-family: var(--fontTer);
  color: #fff;
  width: 60px;
  height: 60px;
  border-radius: 10em;
  background: rgba(0,0,0,0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  position: absolute;
}
.horizontal .valueCircle {
  bottom: var(--centerLineSize, 90%);
  left: 50%;
  transform: translate(-50%, 0);
  margin-bottom: 22px;
}
.vertical .valueCircle {
  top: 50%;
  right: var(--centerLineSize, 90%);
  transform: translate(0, -50%);
  margin-right: 22px;
}

.horizontal .inputRange {
  position: relative;
  margin-bottom: 10px;
  margin-top: 10px;
  height: 100%;
  width: 100%;
  min-height: 38px;
}
.vertical .inputRange {
  position: relative;
  margin-right: 10px;
  margin-left: 10px;
  height: 100%;
  width: 100%;
  min-width: 38px;
}

.horizontal .inputRangeCenterLine {
  width: 3px;
  height: var(--centerLineSize, 80%);
  transform: translate(-50%, 0);
  background: rgba(255,255,255,.7);
  position: absolute;
  border-radius: 1em;
}
.vertical .inputRangeCenterLine {
  height: 3px;
  width: var(--centerLineSize, 90%);
  transform: translate(0%, -50%);
  background: #fff;
  position: absolute;
}

.horizontal .inputRangeLines {
  display: flex;
  height: 90%;
  width: auto;
  align-items: flex-end;
  flex-direction: row;
  transform: translate(-50%, 0);
  position: absolute;
  bottom: 0;
  left: 50%;
}
.vertical .inputRangeLines {
  display: flex;
  width: 90%;
  height: auto;
  align-items: flex-end;
  flex-direction: column;
  transform: translate(0%, -50%);
  position: absolute;
  right: 0;
  top: 50%;
}

.horizontal .line {
  height: var(--lineSize, 40%);
  width: 1px;
  background: #585353;
  margin: 0 5px;
  flex-shrink: 0;
  
  &.bigLine {
    background: yellowgreen;
    width: 2px;
    height: var(--bigLineSize, 55%);
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.vertical .line {
  width: var(--lineSize, 40%);
  height: 1px;
  background: rgb(194, 194, 194);
  margin: 5px 0;
  flex-shrink: 0;
  
  &.bigLine {
    background: white;
    width: var(--bigLineSize, 60%);
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}