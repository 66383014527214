.query {
  display: flex;
  flex-direction: row;
}

.query input {
  display: block;
  appearance: none;
  font-size: 14px;
  padding: 6px 10px;
  margin-left: 6px;
  color: #666;
  border: 1px solid #ccc;
  border-radius: 1em;
  outline: none;
}

.submitButton {
  display: block;
  font-size: 14px;
  padding: 6px 10px;
  margin-left: 6px;
  color: #666;
  border: 1px solid #ccc;
  border-radius: 1em;
  outline: none;
  transition: 200ms;

  &:hover {
    background: rgb(255, 254, 254);
  }
}

@media screen and (max-width: 768px) {
  .query input{
    max-width: 100px;
  }

}
