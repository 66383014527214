.container {
  line-height: 1.4;
}

.lastClicked {
  font-size: 11px;
  color: var(--lightBlack);
}

.timeAgo {
  font-size: 11px;
  color: var(--lightGray)
}



  @media (prefers-color-scheme: dark) {
    .timeAgo { color: #fff}
    .lastClicked { color: #999;}
  }