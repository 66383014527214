.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100000;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate(0%, -100%);
  transition: 200ms ease-in-out;
  pointer-events: none;
}

.content {
  background: #5C4782;
  padding: 12px 32px;
  color: #fff;
  margin: 12px;
  filter: blur(20px);
  opacity: 0;
  transition: 200ms ease-in-out;
  border-radius: 1000em;
  width: auto;
  font-family: var(--fontTer);
}

.shown {
  transform: translate(0, 0);
}

.shown .content {
  opacity: 1;
  filter: blur(0);
}