.masks {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.mask.mask {
  margin: 10px;
  width: 140px;
  height: 140px;
}