.container {
  width: 280px;
  height: 280px;
  border-radius: 20px;
  position: relative;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  box-shadow: 1px 5px 14px rgba(0, 0, 0, 0.21);
  transition: all 200ms ease-in-out;
}

.bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #3F5053;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  color: white;
  font-family: var(--fontSec);
  padding: 14px 16px;
  transition: all 200ms ease-in-out;
  min-height: 70px;
  display: grid;
  place-items: center;
  gap: 3px;

  .title {
    font-weight: bold;
    font-size: 18px;
    display: block;
  }

  .subtitle {
    font-size: 15px;
    line-height: 16px;
    display: block;
  }
}

.priceContainer {
  font-family: var(--fontSec);
  font-size: 28px;
  display: flex;
  min-height: 66px;
  color: white;
  position: absolute;
  top: 16px;
  right: 16px;

  .price {
    position: relative;
    display: block;
    padding-left: 20px;
    font-weight: bold;
  }


  .price:before {
    content: "$";
    position: absolute;
    top: -5px;
    color: #3f50538f;
    left: 10px;
    font-family: var(--fontSec);
    font-size: 14px;
    font-weight: bold;
  }
}


.container.selected, .container:hover {
  transition: all 200ms ease-in-out;
  transform: scale(1.05);

  .bottom {
    background-color: var(--danger);
    transition: all 200ms ease-in-out;
  }
}