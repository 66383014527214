.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 10;
  flex-grow: 1
}

.input {
  margin: 12px;
}

.image {
  width: 100%;
  height: auto;

  img {
    width: 100%;
    height: 100%;
    display: block;
  }
}

.tiledBackgroundWrap {
  position: relative;
}

.tiledBackground {
  display: block;
  width: 100%;
  height: 400px;
  background-size: 50%;
  background-repeat: no-repeat;

  &.tiled {
    background-repeat: repeat;
  }
}

.smallTiledBackground {
  display: block;
  width: 100%;
  height: 200px;
  background-size: 12.5%;
  background-repeat: no-repeat;

  &.tiled {
    background-repeat: repeat;
  }
}

.mask {
  width: 100%;
  height: auto;
}

.patterns {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.pattern {
  margin: 2px;
  width: 60px;
  height: 35px;
  border-radius: 2px;
}

.anchors {
  display: flex;
  flex-direction: row;
  margin: 12px;
}

.anchor {
  flex-grow: 1;
  flex-basis: 0;
}

.feature {
  margin: 4px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 6px;
  display: flex;
  flex-direction: row;
}

.featureContent {
  display: flex;
  flex-direction: column;
}

.featureName {
  font-size: 12px;
  font-weight: 600;
  color: var(--secondary-w90-text-color);
  margin-bottom: 4px;
}

.featureDescription {
  font-size: 12px;
  color: var(--secondary-w90-text-color)
}

.featurePrice {
  font-size: 12px;
}

.removeFeature {
  width: 20px;
  height: 20px;
  display: block;
  flex-shrink: 0;
  transition: 200ms;
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }

  &:hover {
    --tone1: red;
  }
}

.oldPrice {
  text-decoration: line-through;
}