.container {
  flex-shrink: 0;
  margin: 0.3em 0.5em;
}

.title {
  font-size: 11px;
}

.status{
  margin-bottom: 1em;
  font-size: 12px;
}

.linkContainer {
  max-width: 100%;
  height: 20px;
  cursor: pointer;
  display: block;
}

.swatches {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.processImageButtons {
  width: 100%;
  padding: 20px;
  display: flex;
  padding-bottom: 0;
}

.processImageButtons button {
  padding: 8px 10px;
  width: 100%;
  font-size: 11px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.reject {
  background-color: rgb(209, 41, 41);
}

.unsure {
  background-color: rgb(245, 192, 16);
}

.accept {
  background-color: rgb(57, 182, 57);
}

.reprint {
  background-color: rgb(47, 133, 133);
}

.pattern {
  margin: 2px;
  width: 60px;
  height: 35px;
  border-radius: 4;
}

.edit {
  display: block;
  text-align: center;
  margin: 10px 14px;
}

.itemHeader {
  align-self: stretch;
  padding: 0.25em;
}

.lowercase{
  text-transform: lowercase;
}

ul.measurements {
  // display: flex;
  // flex-direction: column;
  // margin: 10px 14px;
  font-size: 12px;
  line-height: 1.5em;
  color: #606060;
  padding: 1em;

  li{

    align-items: baseline;
		display: flex;
		flex-wrap: wrap;
    line-height: 1;
    &::before{
      border-bottom: 1px solid var(--primary-w50-color);
			content: "";
			flex-grow: 1;
			height: 1em;
			order: 2;
			margin: 0 3px;
    }
    span{
      order: 3;
      padding-top: 0.5em;
    }
    .value {
      font-family: var(--fontQuad);
      font-size: 13px;
    }
  }

}

.row {
  display: flex;
  flex-direction: row;

  & > *  {
    flex-basis: 0;
    flex-grow: 1;
    display: block;
  }
}

.actionButton{
  --tone1: #fff;
  margin: 0 5px;
  transition: 300ms;

  &:hover{
    background-color: var(--primary-b10-color);
  }

  svg{
    width: 22px;
    height: 22px;
    &:first-child{
      margin-right: 10px;
    }
  }
}

@media (prefers-color-scheme: dark) {
  .container {background-color: #333; color: #fff; border-color: #444}
  ul.measurements {
    color: #fff;

    li::before{
      border-color: var(--primary-b70-color);
    }
  }

}
