.header {
  font-size: 14px;
  color: #333333;
  letter-spacing: -0.02em;
  box-sizing: border-box;
  height: var(--nav-closed-width);
  padding: 0px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: sticky;
  background: #fff;
  z-index: 1;
  top: 0;
  min-height: 44px;
}

.sticky {
  left: 0;
  z-index: 2;
  border-right: 1px solid rgba(0,0,0,.1);
}
.header.col0 {
  padding-left: 40px;
}


@media (prefers-color-scheme: dark) {
  .header {
    background-color: #1a1a1a;
    color: #fff
  }
}
