.container {
  flex-shrink: 0;
  margin: .5em;
  label {
    font-size: 14px;
  }
}

.lastMessageDetails {
  width: 100%;
  display: inline-block;
}

.details {
  overflow: hidden;
  width: 100%;
}

.error {
  color: var(--danger);
  font-size: 12px;
}

.row {
  display: flex;
  justify-content: space-between;
}

.textBtn {
  font-size: 12px;
  cursor: pointer;
  color: var(--primary);

  &:hover {
    color: var(--primaryHover);
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 12px;
}

summary {
  cursor: pointer;
  outline: none;
  color: #808080;
  display: block;
}

.message {
  color: #000;
  clear: both;
  line-height: 18px;
  font-size: 12px;
  padding: 8px;
  position: relative;
  margin: 8px 0;
  max-width: 85%;
  word-wrap: break-word;
  z-index: 1;
  display: block;

  a {
    text-decoration: none;
  }
}

.metadata {
  float: right;
  position: relative;
  font-size: 10px;
  color: #aaa;
}

$box: rgb(229, 229, 229);

.messageReceived {
  background: $box;
  border-radius: 0px 5px 5px 5px;
  float: left;
}

.messageSent {
  background: #f7f7f7;
  border-radius: 5px 0px 5px 5px;
}