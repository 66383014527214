.select select {
  display: block;
  appearance: none;
  font-size: 14px;
  padding: 6px 10px;
  margin-left: 6px;
  color: #666;
  border: 1px solid #ccc;
  border-radius: 1em;
  outline: none;
  text-align-last: center;
}

.select {
  position: relative;
  align-self: center;
  text-align: right;
}

.select:after {
  content: '';
  position: absolute;
  width: 0; 
  height: 0; 
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  
  border-top: 4px solid #999;
  top: 50%;
  right: 4px;
  transform: translate(0, -50%);
  pointer-events: none;
}

@media screen and (max-width: 768px) {
  select{
    max-width: 70px;
  }
}
