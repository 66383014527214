.boxes {
  display: flex;
  flex-direction: row;
}

.enhancingBox, .enhancingBoxDisabled {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2a4854;
  --tone1: #fff;
  flex-grow: 1;
  margin: 10px;
  width: 280px;
  height: 280px;
  border-radius: 20px;
  overflow: hidden;
  transition: 200ms;
  cursor: pointer;

  svg {
    width: 40%;
    height: auto;
    display: block;
  }

  &.selected, &:hover {
    transition: all 200ms ease-in-out;
    transform: scale(1.05);
  }
}

.enhancingBoxDisabled {
  background: #aeaeae;
  --tone1: transparent;
  font-size: 3em;
  color: #fff;
}