.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.next, .prev, .label {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.2em 0.4em;
  text-decoration: none;
  font-size: 11px;
  color: #aaa;
  letter-spacing: 0.06em;
  --tone1: #999;
  cursor: not-allowed;
  

  svg {
    height: 1.5em;
    width: auto;
    display: block;
    margin: 0;
    padding: 0;
  }
}

.active {
  cursor: pointer;
  color: #333;
  --tone1: #444;
  font-weight: bold;

  &:hover {
    color: var(--secondary-color);
    --tone1: var(--secondary-color);
  }
}

.prev > span {
  padding-left: 0.25em;
}

.next > span {
  padding-right: 0.25em;
}


.label {
  display: flex;
  flex-direction: row;
}
.label span {
  font-weight: 600;
  align-self: center;
  color: #666;
  font-size: 12px;
}
.label select {
  display: block;
  appearance: none;
  font-size: 14px;
  padding: 6px 15px 6px 6px;
  margin-left: 6px;
  color: #666;
  border: 1px solid #999;
  outline: none;
  text-align-last: center;
}

.label .select {
  position: relative;
  align-self: center;
  text-align: right;
}

.label .select:after {
  content: '';
  position: absolute;
  width: 0; 
  height: 0; 
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  
  border-top: 4px solid #999;
  top: 50%;
  right: 4px;
  transform: translate(0, -50%);
  pointer-events: none;
}

@media (prefers-color-scheme: dark) {
  .next, .prev, .label {
    color: #333;
    --tone1: #333;
  }
  .active {
    color: #fff;
    --tone1: #fff;
  }
}