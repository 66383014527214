.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 10;
  flex-grow: 1;
  /* don't put margin here put it in .scrollable */
}

.input {
  margin: 12px;
}

.scrollable {
  margin: 0.5em;
}


.emailForm {
  padding: 20px;
  display: flex;
  flex-direction: column;
  background-color: #f1f1f1;
  border-bottom: 1px solid #ccc;
}

.sendButton {
  width: 100%;
  padding: 16px;
}


.reject {
  background-color: rgb(209, 41, 41);
}

.accept {
  background-color: rgb(57, 182, 57);
  margin-right: 0.5em;
}

.processButtons {
  width: 100%;
  padding: 20px;
  display: flex;
  padding-top: 0;
}

.processImageButtons button {
  padding: 8px 10px;
  width: 100%;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -0.03em;
}

.customEmail{
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  transition: 300ms;

  &:hover{
    --tone1: var(--primaryHover);
  }

  svg{
    margin-right: 5px;
    // --tone1: #fff;
  }
}

details[open] summary ~ * {
  animation: open 0.3s ease-in-out;
}

@keyframes open {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
details summary::-webkit-details-marker {
  display: none;
}

details summary {
  width: 100%;
  padding: 0.5rem 0;
  border-top: 1px solid #ddd;
  position: relative;
  cursor: pointer;
  transition: 300ms;
  font-size: .9em;
}

details summary:hover{
  color: var(--primaryHover);
  border-color: var(--primaryHover);
}

details summary:after {
  content: "+";
  color: rgb(181, 181, 181);
  position: absolute;
  line-height: 0;
  margin-top: 0.75rem;
  right: 0;
  font-weight: 200;
  transform-origin: center;
  transition: 200ms linear;
}
details[open] summary:after {
  transform: rotate(45deg);
  font-size: 1.3rem;
}
details summary {
  outline: 0;
}
details p {
  font-size: 0.95rem;
  margin: 0 0 1rem;
  padding-top: 1rem;
}


.firebaseLink {
  text-align: center;
  margin-top: 20px;
}


@media (prefers-color-scheme: dark) {
  .container {background-color: #222; color: #fff}
}