.container {
  overflow: hidden;
  height: 100vh;
  
}

.content {
  margin-right: var(--sidepanel-width);
  height: 100%;
  transition: 200ms;
}

.patterns {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 6px;
  transition: 200ms;
}
.pattern {
  margin: 6px;
}

.patternGroup {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.patternGroupTitle {
  text-transform: uppercase;
  color: #333;
  font-weight: bold;
  margin: 10px;
}

.patternGroupContent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}