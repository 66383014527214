.header {
  flex-shrink: 0;
  height: 60px;
  display: flex;
  flex-direction: row;
  background: #fff;
  color: #444;
  justify-content: space-between;
}
.header .title {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 16px;

  font-family: var(--fontQuad);
  letter-spacing:0.03em;
  //don't make this uppercase orderId's are case sensitive
  // text-transform: uppercase;
  letter-spacing: 0.05em;
}

.closeButton {
  border: none;
  outline: 0;
  display: block;
  height: 100%;
  padding: 8px 16px;
  transition: 200ms;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;

  &:hover {
    background: var(--primary-color);
    --tone1: var(--primary-text-color);
    --tone2: var(--primary-text-color);
  }
  
  svg {
    height: 50%;
    width: auto;
  }
}


@media (prefers-color-scheme: dark) {
  .header {background-color: #1a1a1a; color: rgb(181, 181, 240)}
}