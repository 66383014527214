.container {
  overflow: hidden;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.content {
  margin-right: var(--sidepanel-width);
  transition: 200ms;
  display: flex;
  flex-direction: column;
  justify-items: stretch;
  flex-grow: 1;
}

.cell {
  white-space: unset;
  overflow: visible;
}

.faded {
  opacity: 0.5;
}

.center {
  justify-content: center;
  align-items: center;
}

.columns {
  display: flex;
  flex-direction: column;
}

.usageArea {
  border-bottom: 1px solid rgba(0, 0, 0, 0.26);
  padding-bottom: 10px;
  margin-bottom: 10px;

  p {
    display: flex;
    justify-content: space-between;
    margin: 0;
    gap: 40px;
  }

  &:last-child {
    border-bottom: unset;
    margin-bottom: unset;
    padding-bottom: unset;
  }
}

.code {
  font-family: var(--fontQuad);
  font-size: 16px;
}

.codeType {
  padding-left: 0.5em;
}
