.container {
  background: #f9f9f9;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.content {
  display: flex;
  flex-direction: column;
}

.container h5 {
  margin: 10px;
  text-align: left;
}
.container h6 {
  margin: 10px;
  text-align: right;
}

.button {
  display: block;
  text-decoration: none;
  background: var(--secondary-color);
  color: var(--secondary-text-color);
  border-radius: 100em;
  padding: 12px;
  text-align: center;
  font-size: 16px;
  transition: 200ms;
}

.button:hover {
  background: var(--secondary-b20-color);
  color: var(--secondary-b20-text-color);
  box-shadow: 0 1px 3px rgba(0,0,0,.2);
}

.heading{
  letter-spacing: -0.03em;
  text-align: center;
  margin: 1em 0;
}

.heading span{
  font-weight: normal;
  opacity: .3;
  font-size: 20px;
}


@media (prefers-color-scheme: dark) {
  .container {background-color: rgba(38, 40, 56, 0.589); color: #fff}
}