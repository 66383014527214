.container {
  background: var(--primary-w20-color);
  color: var(--primary-w20-text-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.authBox {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  width: 100%;
}

.input {
  margin: 10px 5px;
}

.submit {
  margin: 10px 5px;
  padding: 10px;
  background: var(--secondary-color);
  font-size: 16px;
}

.authBox a, .authBox a:visited {
  color: var(--secondary-color);
  text-decoration: none;
  align-self: center;
  margin: 10px;
}