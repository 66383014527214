.container {
  overflow: hidden;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.content {
  margin-right: var(--sidepanel-width);
  transition: 200ms;
  display: flex;
  flex-direction: column;
  justify-items: stretch;
  flex-grow: 1;
}

.content img {
  object-fit:  cover;
  width: 100%;
  height: 100%;
}

.sidebarContent {
  padding: 0 2em;
}

.sidebarContent h1 {
  font-size: 32px;
  color: #323333;
  font-weight: bold;
}

.sidebarContent p {
  font-size: 17px;
  color: #323333;
  font-weight: normal;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 0.5em;
}

.acceptButton, .rejectButton {
  cursor: pointer;
  transition: 200ms;
  margin: 0.5em;
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    display: block;
    height: 88px;
    width: 88px;
  }

  .shortuct {
    font-size: 12px;
  }

  &:hover {
    filter: drop-shadow(0 2px 4px rgba(0,0,0,0.2));
  }
}

.acceptButton {
  --tone1: #2DAD38;
}

.rejectButton {
  --tone1: #F55B5D;
}

.shortcut {
  margin-top: 6px;
  padding: 2px 6px;
  border-radius: 8px;
  background: #f6f6f6;
  border: 1px solid #999;
  box-shadow: 0px 1px 0 1px rgba(0,0,0,0.2);
}

.startButton {
  line-height: 100%;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  font-weight: bold;
  border-radius: 3em;
  color: white;
  font-size: 26px;
  padding: 26px 90px;
  background: var(--primary-color);
  transition: 200ms;

  &:hover {
    background: var(--primary-b20-color);
  }
}

.startContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: 100vh;
}


@media (prefers-color-scheme: dark) {
  .sidebarContent {
    h1, p{ color: #fff}
  }

  .shortcut{
    background-color: #444;
    border-color: #444;
  }
}