$gray: rgb(169, 169, 169);


.container {
  overflow: hidden;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.shipHeader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.shipIcon {
  --tone1: white;
  margin-right: 4px;
}

.content {
  margin-right: var(--sidepanel-width);
  transition: 200ms;
  display: flex;
  flex-direction: column;
  justify-items: stretch;
  flex-grow: 1;
}

.selectLabel {
  position: relative;
  margin-left: 10px;
  margin-right: 10px;

  select {
    text-align-last: left;
  }
}

.statusOuter{
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.status {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  letter-spacing: -0.03em;
  padding: 1em 4em;
}


.unpaid {
  @extend .status;
  background-color: rgb(240, 235, 164) !important;
  color: #333;
}

.new {
  @extend .status;
  background-color: rgba(137, 216, 255, 0.38) !important;
  color: #333;
}

.toVerify, .verifying {
  @extend .status;
  background-color: rgb(255, 246, 115) !important;
  color: #333;
}

.emailed {
  @extend .status;
  background-color: rgb(115, 234, 255) !important;
  color: #333;
}


.accepted {
  @extend .status;
  background-color: rgba(126, 223, 148, 0.51) !important;
  color: #333;
}

.unsure {
  @extend .status;
  background-color: rgb(255, 183, 0) !important;
  color: #333;
}

.rejected {
  @extend .status;
  background-color: rgba(255, 207, 207, 0.33) !important;
  color: #333;
}

.resubmissionRequested {
  @extend .status;
  background-color: var(--primary-w80-color) !important;
  color: #333;
}
.waitingOnImages {
  @extend .status;
  background-color: var(--primary-w80-color) !important;
  color: #333;
}

.doNotPrint {
  @extend .status;
  background-color: rgb(218, 104, 104) !important;
  color: #333;
}

.printed {
  @extend .status;
  background-color: rgb(0, 192, 160) !important;
  color: #333;
}

.shipped {
  @extend .status;
  background-color: rgb(0, 192, 80) !important;
  color: #333;
}


.completed {
  @extend .status;
  background-color: var(--primary-w80-color) !important;
  color: #333;
}

.actions {
  //padding-right: 80px;
  display: flex;
  gap: 2px;
  cursor: pointer;
}

.preview {
  position: absolute;
}

.shippingHeader {
  padding-left: 30px;
}

.shipping {
  line-height: 1.6;
  cursor: pointer;
  padding-left: 30px;
}

.pointer {
  cursor: pointer;
}

.columnLayout {
  display: flex;
  flex-direction: column;
  line-height: 1.4;
}

.orderName{
  color: var(--primary);
  font-weight: bold;
}

.orderEmail{
  color: var(--primary-b20-color)
}

.products {
  display: flex;
  flex-direction: column;
}

.product {
  font-size: 16px;
}

.actionItemIcon {
  width: 16px;
  height: 16px;
  max-width: 16px;
  --tone1: white;
}

.actionBtn {
  border-radius: 3px;
  background-color: var(--dangerDark);
  padding: 6px 8px;
  --tone1: white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  svg {
    width: 16px;
    height: 16px;
    max-width: 16px;
  }

  &:hover {
    background-color: var(--danger);
  }
}

.error {
  max-width: 200px;
  white-space: normal;
  color: var(--dangerDark);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.9);
  z-index: var(--zIndexPopup);
  display: flex;
  flex-direction: row;
}

.overlayContent {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
  z-index: var(--zIndexPopup);
}

.backButton {
  position: absolute;
  top: 0;
  right: 0;
  margin: 20px;
  width: 44px;
  height: 44px;
  --tone1: var(--primary-color);
  transition: 200ms;
  z-index: var(--zIndexPopup);

  svg {
    display: block;
    height: 100%;
    width: 100%;
  }

  &:hover {
    transform: scale(1.1);
  }
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #222;
    color: #fff
  }
  .orderName, a {
    color: #fff
  }

  .accepted {
    color: #fff;
  }

  .waitingOnImages {
    background-color: var(--primary-b60-color) !important;
    color: #fff;
  }
  .resubmissionRequested{
    background-color: var(--primary-b80-color) !important;
    color: #fff;
  }

  .toVerify, .verifying {
    background-color: rgb(103, 104, 67) !important;
    color: #fff;
  }
}
