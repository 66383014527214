@import "fonts/fonts.css";

body {
  --primary: #4f87b0;
  --primaryHover: #0078cb;
  --lightGray: #8f95a1;
  --gray: #7A7878;
  --lightBlack: #4b4b4b;
  --black: #323232;
  --dangerLight: #ffebeb;
  --danger: #f24051;
  --dangerDark: #c84755;
  --success: #70AA09;

  --font: 'Rubik', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  --fontSec: 'Recoleta';
  --fontTer: 'Greycliff CF';
  --fontQuad: 'Inconsolata';


  --tone1-default: rgba(192, 192, 192, 0.9);
  --tone2-default: rgba(160, 160, 160, 0.4);
  --tone3-default: rgba(160, 160, 160, 1.0);
  --tone1-opacity-default: 1;
  --tone2-opacity-default: 1;
  --tone3-opacity-default: 1;

  --nav-closed-width: 52px;
  --nav-opened-width: 310px;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: var(--font);
  font-size: 16px;
  font-weight: 400;
  margin: 0;


  --zIndexSidebar: 2000;
  --zIndexPopup: 10000;
  --zIndexSticky: 1000;

  --sidepanel-width-opened: 400px;
}

svg {
  transition: 200ms;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

a {
  color: var(--primary);
}

a:hover {
  color: var(--primaryHover);
}

::selection {
  background-color: var(--lightBlack);
  color: #fff;
}