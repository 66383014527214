.patterns {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.pattern {
  margin: 10px;
  width: 100px;
  height: 100px;
  display: block;
  overflow: hidden;
  transition: 200ms;
  cursor: pointer;
  position: relative;
  border-radius: 5px;
  overflow: hidden;

  &:hover, &.selected {
    box-shadow: 0 2px 5px rgba(0,0,0,0.3);
    transform: scale(1.05);
  }
  
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    pointer-events: none;
    border: 4px solid transparent;
    border-radius: 5px;
  }

  &.selected:after {
    border-color: var(--primary-color);
  }
}

.name {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 6px;
  background: rgba(0,0,0,0.5);
  color: #fff;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 200ms;
}

.pattern.selected .name, .pattern:hover .name {
  opacity: 1;
}

.image {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  object-position: top;
  border-radius: 5px;
}