.content {
  margin-left: var(--nav-closed-width);
  transition: 200ms;
}
.opened {
  margin-left: var(--nav-opened-width);
}

@media print {
  .content {
    margin: 0;
  }
}
