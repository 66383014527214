.container {
  overflow: hidden;
  height: 100vh;  
  display: flex;
  flex-direction: column;
}

.content {
  margin-right: var(--sidepanel-width);
  transition: 200ms;
  display: flex;
  flex-direction: column;
  justify-items: stretch;
  flex-grow: 1;
}