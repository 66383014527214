.container {
  display: flex;
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 3px;
  align-items: center;
  justify-content: center;
  transition: 300ms;
  box-shadow: 0 0px 0px rgba(0,0,0,0.3), 0 0 0 1px rgba(0,0,0,0.1);
  cursor: pointer;
  position: relative;

  &:hover {
    box-shadow: 0 2px 5px rgba(0,0,0,0.3);
    transform: scale(1.05);
  }

  input[type="file"] {
    display: none;
  }
}

.image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: top;
}



.create {
  font-weight: bold;
  font-size: 18px;
}

.name {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 6px;
  background: rgba(0,0,0,0.5);
  color: #fff;
  text-align: center;
}

.order {
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  padding: 6px;
  background: rgba(0,0,0,0.8);
  border-radius: 0 0 0 6px;
  min-width: 31px;
  text-align: center;
}

.somethingSelected {
  transform: scale(0.9);
  filter: grayscale(80%);
  &:hover {
    filter: grayscale(50%);
  }
}
.selected {
  transform: scale(1.0);
  filter: none;
  &:hover {
    filter: none;
  }
}

.disabledLabel {
  position: absolute;
  top: 0;
  left: 0;
  color: var(--primary-color);
  padding: 6px;
  padding: 6px;
  background: rgba(0,0,0,0.8);
  border-radius: 0 0 0 6px;
}

.smallContainer.selected {
  box-shadow: 0 0 0 2px var(--secondary-color);
}

.hasTilesWrap {
  position: absolute;
  top: 0;
  left: 0;
  padding: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hasTiles {
  background: var(--secondary-color);
  border-radius: 50%;
  height: 10px;
  width: 10px;
  margin-bottom: 3px;
  opacity: 0.8;
}

.hasSmallTiles {
  background: var(--secondary-color);
  border-radius: 50%;
  height: 6px;
  width: 6px;
  opacity: 0.8;
  margin-bottom: 3px;
}

.hasS88 {
  background: var(--secondary-color);
  border-radius: 1px;
  height: 6px;
  width: 6px;
  opacity: 0.8;
  margin-bottom: 3px;
}