.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.7);
  z-index: 1;
  transition: 200ms;

  animation: backgroundAppear 200ms ease-in-out;
}

.content {
  background: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  z-index: 2;
  color: #333;
  position: relative;
  animation: contentSlide 250ms ease;
  max-height: 100%;

  max-width: 600px;
  text-align: center;
}

.contentBody {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  border-radius: 8px;
  padding: 16px;
  overflow: auto;
  min-width: 400px;

  h4 {
    font-size: 1.1em;
    margin: 0;
    padding: 0;
  }
}

.circle {
  border-radius: 50%;
  background: #fff;
  position: absolute;
  top: 0;
  left: 50%;
  align-self: center;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0,0,0,0.1);
  transform: translate(-50%, -50%);
}
.circle svg {
  height: 50%;
  width: auto;
}

.circle + .contentBody {
  padding-top: 44px;
}


@keyframes backgroundAppear {
  from {
    opacity: 0;
  }
}

@keyframes contentSlide {
  from {
    transform: translate(0, -80px);
    opacity: 0.2;
  }
}
